* {
    font-family: Omoweb !important;
}
.table-detaile {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  margin: 25px;
  background: #fff;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 black;
}
li.detailed {
  padding: 5px;
}